<template>
    <CRow class="mt-2">
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <CCol sm="12">
        <CTabs :active-tab="Tab" @update:activeTab="handleTab">
          <CTab :title="$t('label.container')">
            <Container :Tab="Tab" class="ml-1 mr-1"/>
          </CTab>
           <CTab :title="$t('label.bulkCargoName')">
            <BulkCargo :Tab="Tab" class="ml-1 mr-1"/>
          </CTab>
          <CTab :title="$t('label.generalCargo')">
            <GeneralCargo :Tab="Tab" class="ml-1 mr-1"/>
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
</template>
  
  <script>
  import { mapState } from "vuex";
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  import Container from './container';
  import BulkCargo from './bulkCargo';
  import GeneralCargo from './generalCargo';
  
  //Funcion data() del ciclo.
  function data() {
    return {
      isSubmit: false,
      Tab: 0,
      TpCargoJson: [],
      Loading: false
    }
  }
  function getListContainerIndex() {
    this.Loading = true;
    this.$http
    .get('TpCargo-list',{ Filter:'ACTIVO'})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.lenght != 0){
        this.TpCargoJson = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
  }
  //Methods:
  function handleTab(Tab) {
    this.Tab = Tab;
  }
  function isActiveTab(current) {
    return this.Tab == current;
  }
  
  export default {
    name: 'cargo-type',
    components: {
      CustomTabs,
      CustomTab,
      Container,
      BulkCargo,
      GeneralCargo
    },
    props: {
      active: {
        type: Number,
        default: 0,
      },
    },
    data,
    methods: {
      getListContainerIndex,
      handleTab,
      isActiveTab
    },
    computed: {
      ...mapState({
        activeTab: state => state.yard.tabIndex,
        yardSelected: state => state.yard.yardSelected
      }),
    },
    watch: {
      activeTab: function (newTab) {
        if (newTab == 1) {
          this.Tab = 0;
        }
      },
    }
  }
  </script>
  
  <style scoped>
  .first { 
    position: sticky; 

  } 
  
  </style>