<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mt-4">
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formatedItems"
          :fields="fieldsTpCargo"
          :noItemsView="tableText.noItemsViewText"
          hover
          small
          sorter
          pagination
          :items-per-page="5"
        >
        <template #TpCargoId="{ index}">
          <td>
            {{index+1}}
          </td>
        </template>
        <template #Status="{ item }">
          <td class="text-center">
            <CSwitch
              color="success"
              variant="opposite"
              :checked.sync="item.Status"
              @update:checked="addRemoveId(item)"
            >
            </CSwitch>
          </td>
        </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" class="text-right">
        <CButton 
          color="add" 
          class="first"
          @click="sendData()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>
    
<script>
import General from "@/_mixins/general";
import { mapState } from "vuex";
       
//DATA
function data() {
  return {
    items: [],
    arrIds: [],
    Loading:  false,
    TpCargoJson:[],
    arrIdsOrigin: [],
    TpCargoYardId: '',
    TpCargoId: '',
    Status: 0,
    GpoCargoId: 'a1b18468-2fbf-463c-8e79-8037e4b9acbd'
  };
}

//METHOD
  function addRemoveId(item) {
    let bool = this.arrIds.filter((e) => e.id == item.TpCargoId)
    if( bool.length == 0 ){
      //ADD
      this.arrIds.push({id: item.TpCargoId, idSelect: ''});
    }else{
      //DELETE
      this.arrIds = this.arrIds.filter((e) => e.id !== item.TpCargoId);
    } 
  }


  function getListBulkCargo() {
    this.Loading = true;
    this.$http
    .get('TpCargoYardAll-list',{ YardId: this.getYardId, GpoCargoId: this.GpoCargoId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.lenght != 0){
          this.TpCargoJson = listado;
            this.TpCargoJson.map(item =>{
              if (item.TpCargoYardId) {
                this.arrIdsOrigin.push({id: item.TpCargoId, idSelect: item.TpCargoYardId});
              }
        })
      }
      listado.map((item) => {
          if ( item.TpCargoYardId !== null ) {
            this.arrIds.push({id: item.TpCargoId, idSelect: item.TpCargoYardId});
          }
      });
    })        
    .finally(() => {
      this.Loading = false;
    });
  }

  function sendData(){
    let res = [];
    this.Loading = true;
    let Json = [];
    let Total_Id = [];

    Total_Id = [...this.arrIdsOrigin, ...this.arrIds];
    Total_Id.map(item =>{
      if (!Json.some(TpCargo => TpCargo.id == item.id )) {
        Json.push(item);
      }
    })

    let TpCargoYardJson = Json.map((item) => {
      let ValidateCurrentCargo = this.arrIds.some(e =>e.id == item.id)
        return {
          YardId: this.getYardId,
          TpCargoYardId:  item.idSelect ? item.idSelect : '',
          TpCargoId: item.id,
          Status: ValidateCurrentCargo ? 1 : 0,
        };
    })

    this.$http.post("TpCargoYard-insert", TpCargoYardJson, { root: 'TpCargoYardJson' })
      .then((response) => {
        res = [...response.data.data];

        this.getYards();

        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: res[0].Response,
          type: 'success',
        });

        this.getListBulkCargo();
        this.Loading = false;

        this.cleanDataSelect();
      })
      .catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: 'error',
        });
        this.Loading = false;
      });
  }

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.$store.state.auth.branch.CompanyBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.lenght != 0){
        let yardItem = listado.find((item)=> item.YardId === this.$store.state.yard.yardSelected.YardId);

        if(yardItem)
          this.$store.state.yard.yardSelected = yardItem;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

  function cleanDataSelect(){
    this.arrIds = [];
    this.TpCargoJson = [];
    this.arrIdsOrigin = [];
  }
    
  // COMPUTED
  function fieldsTpCargo() {
    return [
    { key: 'TpCargoId', label: '#',_style: 'width:1%; text-align:left',filter: false},
    { key: 'TpCargoNameEs',label: this.$t('label.group')+' '+('(ES)'), _style:'min-width:100px;', _classes:'text-center' },
    { key: 'TpCargoNameEn',label: this.$t('label.group')+' '+('(EN)'), _style:'min-width:100px;', _classes:'text-center' },
    { key: 'TpCargoCode',label: this.$t('label.acronym'), _style:'width:20%;', _classes:'text-center'},
    { key: 'Status',label:'',sorter: false,_style:'width:3%;',},
    ]
  }

  function formatedItems() {
    if (this.TpCargoJson.length > 0) {
      return this.TpCargoJson.map((item) => {
        let checkeds = this.arrIds.filter((e) => e.id == item.TpCargoId);

        return {
          ...item,
          Status: (checkeds.length > 0),
        };
      });
    }
  }

  function getYardId() {
    return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
  }

  export default {
    name: "bulk-cargo",
    mixins: [General],
    props:{Tab: Number},
    data,
    components: {},
    methods: {
        getListBulkCargo,
        addRemoveId,
        sendData,
        cleanDataSelect,
        getYards
    },
    computed: {
        fieldsTpCargo,
        formatedItems,
        getYardId,
        ...mapState({
          yardSelected: state => state.yard.yardSelected
        }),
    },
    watch: {
      Tab:function (val){
        if(val == 1){
          this.getListBulkCargo();
          this.cleanDataSelect();
        }
      }
    }
  } 
</script>